// @ts-check
import React from 'react';
import { Localize } from '@planity/components';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './cure_payment_choice.modules.scss';
import { useTranslation } from '@planity/localization';
import { Radio, Icon } from '@planity/ui';
import { noop, PAYMENT_METHOD } from '@planity/helpers';
import { EligibleCuresList } from './eligible-cures-list';
import credentials from '@planity/credentials';

const { USE_CURE_FOR_PAYMENTS_ENABLED } = credentials;

export const CurePaymentChoice = ({
	paymentMethod,
	setPaymentMethod,
	eligibleCures,
	displayCurePaymentMethod,
	hasMultipleEligibleCures,
	allServicesAreCoveredByCures,
	isLoading
}) => {
	const { t } = useTranslation();
	useStyles(styles);
	const classes = classNames.bind(styles)({
		curePaymentChoice: true
	});

	return (
		<div className={classes}>
			<div className={styles.choices}>
				{displayCurePaymentMethod && (
					<Radio
						id={'appointment-cure-payment-radio'}
						isChecked={paymentMethod === PAYMENT_METHOD.USE_CURE}
						name={'cure-voucher'}
						text={() => (
							<div className={styles.inlineContainer}>
								<Icon
									className={styles.inlineContainer__icon}
									icon='CurePayment'
									size={'medium'}
								/>
								<span>
									<>
										<span className={styles.curePaymentLabel}>
											{t('myAccount.cures.paymentLabel')} {'-'}{' '}
										</span>
										<span className={styles.cureLabelText}>
											{t(`onlinePayment.how.cureVoucher.text`, {
												count: Number(hasMultipleEligibleCures)
											})}
										</span>
									</>
									<EligibleCuresList
										eligibleCures={eligibleCures}
										styles={styles}
									/>
									{!allServicesAreCoveredByCures && (
										<span>
											<Localize
												text={
													'onlinePayment.how.cureVoucher.servicesNotCovered'
												}
											/>
										</span>
									)}
								</span>
							</div>
						)}
						textClassName={styles.precision}
						onChange={
							isLoading ? noop : () => setPaymentMethod(PAYMENT_METHOD.USE_CURE)
						}
					/>
				)}
			</div>
		</div>
	);
};
