import { useLocalizedRoutes } from '@planity/localization';
import React, { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { breakpoints, colors, fontWeight } from '@planity/theme';
import credentials from '@planity/credentials';
import { Localize, useInteractive } from '@planity/components';
import { MoreInfos } from './more_infos';
import { noop } from '@planity/helpers';
import { Service } from '@planity/components/book_appointment/steps/service';
import { BusinessReview, BusinessDescription } from '@planity/ui';

import {
	CloseAvailabilities,
	DistantAvailabilities,
	NoAvailabilities
} from './days_availabilities';
import { withFilter } from '../ui/filter/context';
import { sendPickBusinessFromAvailabilities } from '@planity/helpers/analytics';
import { useInView } from 'react-intersection-observer';
import { BusinessItemSearch, Button } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames/bind';
import scssStyles from './business.module.scss';

const slideshowTransformations = {
	default: 't_d_search',
	breakpoints: [
		{ query: breakpoints.phoneQuery, transformation: 't_d_search' },
		{ query: breakpoints.tabletQuery, transformation: 't_d_search' }
	]
};

export default withFilter(function SearchPageResultsBusiness({
	business,
	availabilities,
	services,
	search,
	onBusinessHover,
	isCollapsing,
	userFilter,
	hoveredBusinessId
}) {
	const history = useHistory();
	const { routes } = useLocalizedRoutes();
	const { ref } = useInView({ threshold: 0 });
	const isDirectory = business.plStatus < 3;
	useStyles(scssStyles);
	const classes = classNames.bind(scssStyles);
	const [isCollapsed, setIsCollapsed] = useState(true);
	const contentRef = useRef(null);

	const onMouseEnter = useCallback(
		e => {
			e?.preventDefault();
			if (onBusinessHover) {
				onBusinessHover(business.objectID);
			}
		},
		[business.objectID]
	);
	const onMouseLeave = useCallback(e => {
		e?.preventDefault();
		if (onBusinessHover) {
			onBusinessHover();
		}
	}, []);

	const { handlers } = useInteractive({
		desktopOnly: true,
		onMouseEnter,
		onMouseLeave
	});

	const pictures = !isDirectory && business.pictures;
	const showReviews =
		credentials.DISPLAY_REVIEWS &&
		!business.desactivateReviews &&
		business.globalRating;

	const { nextAvailabilities, isAvailableSoon, firstAvailability } =
		availabilities || {};

	const hasMoreInfos =
		(!business?.desactivateReviews && business?.reviews?.length) ||
		business?.description ||
		services?.length;

	const handleClickBusiness = event => {
		event.preventDefault();
		event.stopPropagation();
		history.push(
			routes.catchAll({
				business: business,
				state: {
					highlightServices: services,
					search: search,
					scrollToPresta: !!(search && search.category)
				}
			})
		);

		const date = userFilter.data && userFilter.data.date;
		if (date) {
			sendPickBusinessFromAvailabilities();
		}
	};

	const hasCloseAvailabilities = Boolean(isAvailableSoon && firstAvailability);
	const hasNextAvailabilities = Boolean(!isAvailableSoon && firstAvailability);
	const hasNoAvailability = business.hasAvailabilities === 0; // hasAvailabilities is exclusive to algolia. it do NOT exist on firebase

	const displayAvailabilities = () => {
		if (hasCloseAvailabilities)
			return (
				<CloseAvailabilities
					isSearch={true}
					nextAvailabilities={nextAvailabilities}
				/>
			);
		if (hasNextAvailabilities)
			return (
				<div className={scssStyles.availabilities}>
					<DistantAvailabilities
						hasAvailabilities={business.hasAvailabilities === 1}
						nextAvailability={firstAvailability}
					/>
				</div>
			);
		if (hasNoAvailability) return <NoAvailabilities />;
		return null;
	};

	const hasFooterContainer = hasMoreInfos || !hasNoAvailability;
	return (
		<div
			className={classes({
				search: true,
				hasOtherCategories: isDirectory,
				isSelected: hoveredBusinessId
			})}
			css={[styles.business, isDirectory && styles.directoryBusiness]}
			id={`business-${business.objectID}`}
			{...handlers}
			ref={ref}
			tabIndex={0}
		>
			<div
				css={[
					styles.infosContainer,
					isDirectory && styles.infosContainerNoDirectory
				]}
				onClick={handleClickBusiness}
			>
				<div
					css={[
						styles.picture,
						pictures && pictures.length === 1 && styles.onePicture,
						(!pictures || !pictures.length) && styles.noPicture
					]}
				>
					<BusinessItemSearch
						availabilities={
							!isDirectory ? (
								process.env.BROWSER ? (
									displayAvailabilities()
								) : null
							) : (
								<Button
									hasOtherCategories={isDirectory}
									label={
										<Localize text='search.businessDirectoryCallToAction' />
									}
								/>
							)
						}
						business={business}
						hasFooterContainer={hasFooterContainer}
						images={pictures}
						isDirectory={isDirectory}
						key={business.objectID}
						showReviews={showReviews}
						transformations={slideshowTransformations}
						URL={
							routes.catchAll({
								business: business
							})?.pathname
						}
					/>
				</div>
			</div>
			{!isDirectory && (
				<div
					className={classes({
						footerContainer: hasFooterContainer
					})}
				>
					<div>
						{services && (
							<ul>
								{services
									.filter(service => service.bookable && !service.hidden)
									.slice(0, 3)
									.map((service, key) => (
										<ServiceComponent
											business={business}
											index={key}
											isHighlightedServices
											key={service.id}
											lengthService={services.length}
											search={search}
											service={service}
											services={services}
										/>
									))}
							</ul>
						)}
					</div>
					<div
						className={classes({ details: true, isCollapsed })}
						ref={contentRef}
					>
						<div className={classes({ reviewsContainer: true })}>
							{showReviews && business?.reviews?.length > 0 && (
								<div>
									<span className={classes({ subtitle: true })}>
										<Localize text={'search.title.reviews'} />
									</span>
									{Object.values(business.reviews).map((review, index) => (
										<BusinessReview
											className={classes({ styles: true })}
											isFiveStars
											key={index}
											note={review.reviewRating}
											outlined
											reply={review.reviewAnswer}
											replyFrom={business.name}
											text={review.reviewBody}
										/>
									))}
								</div>
							)}
							{business.description && (
								<BusinessDescription {...business} isSearch={true} />
							)}
						</div>
					</div>
					<div className={classes({ footerItems: true })}>
						<div className={classes({ footerLeftItem: true })}>
							{!isDirectory && hasMoreInfos && (
								<MoreInfos
									business={business}
									isCollapsed={isCollapsed}
									isCollapsing={isCollapsing}
									isHighlightedServices
									search={search}
									services={services}
									setIsCollapsed={e => setIsCollapsed(e)}
								/>
							)}
						</div>
						<div className={classes({ footerRightBottomItem: true })}>
							{!hasNoAvailability && (
								<Button
									label={<Localize text={'search.businessCallToAction'} />}
									onClick={() => {
										history.push(
											routes.catchAll({
												business: business,
												state: {
													highlightServices: services,
													search: search,
													scrollToPresta: !!search.category
												}
											})
										);
									}}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
});
const ServiceComponent = ({
	services,
	search,
	service,
	index,
	business,
	isHighlightedServices,
	lengthService,
	className
}) => {
	const history = useHistory();
	const { routes } = useLocalizedRoutes();
	const redirectTo = () => {
		if (lengthService <= 3) {
			return history.push(
				routes.catchAll({
					reservation: { business },
					state: {
						business: { ...business, isBooking: true },
						initialServiceId: service.id
					}
				})
			);
		} else {
			return history.push(
				routes.catchAll({
					business,
					state: {
						highlightServices: services,
						search,
						scrollToPresta: true
					}
				})
			);
		}
	};
	return (
		<Service
			alternateColors
			business={business}
			className={className}
			dispatch={noop}
			displayCategoryParent
			isHighlightedServices={isHighlightedServices}
			isSearch
			key={index}
			redirectTo={redirectTo}
			service={Object.assign(
				{
					duration: service.duration,
					prices: service.prices,
					bookable: service.name,
					parentCategory: service.parentName,
					name: service.name
				},
				service.description
					? {
							description: service.description
					  }
					: {}
			)}
			serviceId={service.id}
		/>
	);
};

export function schemaType({ types }) {
	switch (businessMainType(types)) {
		case 'hair_care':
		case 'barber_shop':
			return 'HairSalon';
		case 'spa':
			return 'DaySpa';
		case 'beauty_salon':
			return 'BeautySalon';
		case 'nails':
			return 'NailSalon';
		case 'tatoo':
			return 'TattooParlor';
		case 'fitness':
			return 'SportsActivityLocation';
		default:
			return 'HealthAndBeautyBusiness';
	}
}

function businessMainType(types) {
	return (types || '').split(',').filter(x => !!x)[0];
}

const ellipsis = {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis'
};

const styles = {
	business: {
		position: 'relative',
		overflow: 'hidden',
		cursor: 'pointer',
		WebkitTapHighlightColor: 'rgba(0,0,0,0)',
		height: 'auto',
		flexDirection: 'column',
		[breakpoints.tabletQuery]: {
			display: 'flex',
			alignItems: 'stretch',
			height: 'auto',
			padding: '20px var(--gutter) 0',
			paddingTop: 0
		},
		transition: 'box-shadow .5s ease-in-out',
		outline: 'none'
	},
	directoryBusiness: {
		[breakpoints.tabletQuery]: {
			height: 'auto'
		}
	},
	picture: {
		backgroundColor: 'white',
		position: 'relative',
		overflow: 'hidden',
		[breakpoints.tabletQuery]: {
			width: '100%',
			paddingTop: 0
		}
	},
	onePicture: {
		pointerEvents: 'none'
	},
	noPicture: {
		'position': 'relative',
		'&:after': {
			backgroundImage:
				"url('https://d2skjte8udjqxw.cloudfront.net/pics/logo.svg')"
		}
	},
	infosContainer: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
		[breakpoints.tabletQuery]: {
			flexDirection: 'row',
			height: '100%',
			columnGap: 20,
			paddingTop: 20
		}
	},
	infosContainerNoDirectory: {
		height: 'auto',
		[breakpoints.tabletQuery]: {
			height: 'auto'
		}
	},
	info: {
		[breakpoints.tabletQuery]: {
			width: 'calc(100% - 313px)'
		}
	},
	directoryInfo: {
		width: '100% !important',
		[breakpoints.tabletQuery]: {
			display: 'flex'
		}
	},
	noPictureInnerInfo: {
		padding: '5px 15px 7px 0'
	},
	innerInfo: {
		'height': '100%',
		'padding': '5px 15px 7px 0',
		'display': 'flex',
		'flexDirection': 'column',
		'& > .h2': {
			color: colors.black.background,
			lineHeight: 1.33,
			fontSize: 18,
			height: 24,
			fontWeight: fontWeight.bold,
			paddingLeft: 15,
			...ellipsis,
			[breakpoints.tabletQuery]: {
				paddingLeft: 0
			}
		},
		'& > div > p': {
			'paddingLeft': 12,
			'color': colors.grey.brownGrey,
			'fontWeight': fontWeight.normal,
			'height': 18,
			...ellipsis,
			'& > svg': {
				width: 14,
				height: 14,
				fill: colors.grey.brownGrey,
				marginRight: 3,
				top: 2,
				position: 'relative'
			},
			'fontSize': 12,
			'lineHeight': 1.5,
			[breakpoints.tabletQuery]: {
				paddingLeft: 0,
				fontSize: 14,
				lineHeight: 1.29,
				marginBottom: 8
			}
		}
	},
	directoryInnerInfo: {
		'padding': '15px 15px 14px 0',
		[breakpoints.tabletQuery]: {
			padding: '17px 30.5px 17px 32px',
			marginRight: 'auto',
			overflow: 'hidden'
		},
		'& > .h2': {
			fontSize: 16,
			lineHeight: 1.13,
			margin: '0 0 7px !important',
			height: 'auto'
		},
		'& > div > p': {
			fontSize: 14,
			lineHeight: 1.29,
			marginBottom: '0 !important'
		}
	},
	mobileSubtitle: {
		display: 'flex',
		[breakpoints.tabletQuery]: {
			display: 'block'
		}
	},
	subtitle: {
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		flexShrink: 0,
		[breakpoints.tabletQuery]: {
			flexGrow: 'unset'
		}
	},
	directorySubtitle: {
		display: 'none !important'
	},
	stars: {
		order: 2,
		marginLeft: 'auto',
		[breakpoints.tabletQuery]: {
			marginLeft: 0,
			order: 'initial'
		}
	},
	ratingsCount: {
		padding: '0 0 0 7px',
		height: 15,
		fontSize: 12,
		color: colors.grey.brownGrey,
		order: 3,
		[breakpoints.tabletQuery]: {
			order: 'initial',
			padding: '0 16px 0 7px'
		}
	},
	euros: {
		height: 15,
		fontSize: 12,
		color: 'black',
		order: 1,
		paddingLeft: 15,
		paddingRight: 15,
		[breakpoints.tabletQuery]: {
			paddingRight: 0,
			paddingLeft: 0,
			order: 3
		}
	},
	linkContainer: {
		'& > a': {
			color: 'black',
			textDecoration: 'none',
			outline: 'none'
		}
	}
};
