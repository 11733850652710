import { useModal } from '@planity/ui';
import { Button } from '@planity/ui';
import React from 'react';
import styles from './confirm_cancellation_modal_buttons.module.scss';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from '@planity/localization';

export const ConfirmCancellationModalButtons = ({ onSubmitClick }) => {
	const { t } = useTranslation();
	const [isLoading, setLoading] = React.useState(false);
	useStyles(styles);
	const cx = classNames.bind(styles);
	const { closeModal } = useModal();

	const submitHandler = () => {
		setLoading(true);
		onSubmitClick();
	};

	return (
		<>
			<Button
				className={cx({ cancelButton: true })}
				id='no-confirm-cancel-appointment-button'
				isFullMobile
				label={t('myAccount.appointment.confirmCancellation.stepBack')}
				type='tertiary'
				onClick={() => closeModal()}
			/>
			<Button
				className={cx({ confirmButton: true })}
				id='cancel-appointment-confirm-button'
				isFullMobile
				isLoading={isLoading}
				label={t('myAccount.appointment.confirmCancellation.confirm')}
				type='danger'
				onClick={submitHandler}
			/>
		</>
	);
};
