// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hidden_map-module_button-Dnbc2{position:relative;z-index:200}.hidden_map-module_image-bIk78{position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;z-index:100}.hidden_map-module_mapBackground-DNzK8{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%}", "",{"version":3,"sources":["webpack://./../../packages/ui/uikit/map/hidden_map/hidden_map.module.scss"],"names":[],"mappings":"AAEA,gCACC,iBAAA,CACA,WAAA,CAGD,+BACC,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,WAAA,CAGD,uCACC,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA","sourcesContent":["@use '@planity/ui/styles';\n\n.button {\n\tposition: relative;\n\tz-index: 200;\n}\n\n.image {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tobject-fit: cover;\n\tz-index: 100;\n}\n\n.mapBackground {\n\tposition: relative;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": "64px",
	"searchBarHeight": "100px",
	"button": "hidden_map-module_button-Dnbc2",
	"image": "hidden_map-module_image-bIk78",
	"mapBackground": "hidden_map-module_mapBackground-DNzK8"
};
module.exports = ___CSS_LOADER_EXPORT___;
