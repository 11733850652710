import { PropagateSearch } from '@planity/context';
import groupBy from 'lodash/groupBy';
import {
	ClickAndCollectFlow,
	GooglePlaceById,
	PassThrough,
	useClickAndCollectContext
} from '@planity/components';
import credentials from '@planity/credentials';
import { safeRead } from '@planity/helpers';
import {
	AVAILABLE_LOCALES,
	useLocalization,
	useLocalizedRoutes
} from '@planity/localization';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { BusinessPage } from './business_page';
import {
	CategoryDirectoryPage,
	CategoryPage,
	BusinessCategoriesPage
} from './category_page';
import { AlgoliaSearch } from './data';
import { Layout } from './layout';
import { SearchPage } from './search_page';
import { UnknownURLPage } from './unknown_url_page';
import { Buffer } from 'buffer';
import { isNativeApp } from '@planity/webview';
import { WebviewRedirections } from './webview_redirections';
import {
	matchesAllowedPattern,
	isBounds,
	isUserLocation
} from '@planity/helpers';

export default withRouter(function CatchAllPage({
	location,
	history,
	staticContext
}) {
	const cacContext = useClickAndCollectContext();
	const { language, wholeCountrySlug, localizedBasePath } = useLocalization();

	const { routes } = useLocalizedRoutes();

	const {
		localeSlug,
		hardcodedSlugs = [],
		algoliaSlugs = [],
		googleSlugs = [],
		slugs
	} = locationSlugs(location.pathname, wholeCountrySlug);
	// Equivalent to /^\/?fr-FR|^\/?de-DE/gm
	const localeMatcher = new RegExp(
		AVAILABLE_LOCALES.map(locale => `^/?${locale}`).join('|'),
		'gm'
	);
	const locationWithoutLocale = {
		localeSlug,
		...location,
		pathname: location.pathname.replace(localeMatcher, '').toLowerCase()
	};

	const AlgoliaComponent = algoliaSlugs.length ? AlgoliaSearch : PassThrough;
	const GoogleComponent = googleSlugs.length ? GooglePlaceById : PassThrough;

	// reinject data for click and collect
	useEffect(() => {
		if (location?.search?.length > 0) {
			const searchParams = new URLSearchParams(location?.search);
			const tokenData = searchParams.get('tokenData');
			const data = tokenData
				? JSON.parse(Buffer.from(tokenData, 'base64').toString('utf-8'))
				: {};
			const {
				onlineShopData,
				transactionType,
				paymentIntentId,
				paymentMethod
			} = data;
			if (transactionType === 'onlineShop' && onlineShopData) {
				cacContext.functions.reinjectData({
					...onlineShopData,
					paymentIntentId,
					paymentMethod
				});
			}
		}
	}, []);

	return (
		<AlgoliaComponent
			language={language}
			localizeResults
			queries={algoliaQueries(algoliaSlugs, language)}
			useCache
		>
			{({ data: algoliaData, isLoading: algoliaIsLoading } = {}) => (
				<GoogleComponent id={googleQuery(googleSlugs)} useCache>
					{({ place: googlePlace, isLoading: googleIsLoading } = {}) => {
						const { category, search, business, isLoading } = getSearch({
							slugs,
							hardcodedSlugs,
							algoliaSlugs,
							googleSlugs,
							algoliaData,
							googlePlace,
							googleIsLoading,
							algoliaIsLoading,
							locationState: location.state
						});
						if (isLoading) {
							return (
								<Layout hideBreadcrumbs>
									<div />
								</Layout>
							);
						} else if (category || search) {
							if (!process.env.BROWSER) {
								const isDisabled = safeRead(search, ['category', 'disabled']);
								const shouldRedirect = safeRead(search, [
									'category',
									'redirect'
								]);
								const parentPlaceRedirect =
									safeRead(search, ['parentPlace', 'redirect']) || null;
								if (isDisabled && shouldRedirect) {
									if (staticContext) {
										staticContext.status = 302;
										staticContext.url = `${credentials.HOST}/${safeRead(
											search,
											['parentCategory', `slug`]
										)}`;
										return null;
									}
								} else if (parentPlaceRedirect && staticContext) {
									const parentCategorySlug = safeRead(search, [
										'parentCategory',
										'slug'
									]);
									const childCategorySlug = safeRead(search, [
										'category',
										'slug'
									]);
									const childPlaceSlug = safeRead(search, ['place', 'slug']);
									staticContext.status = 301;

									if (
										parentCategorySlug &&
										childCategorySlug &&
										childPlaceSlug
									) {
										/*
										Input => /coiffeur/coupe-homme/gironde-33/33190-gironde-sur-dropt
										Output => /coiffeur/coupe-homme/33190-gironde-sur-dropt
										*/
										staticContext.url = `https://www.${credentials.HOST}${localizedBasePath}/${parentCategorySlug}/${childCategorySlug}/${childPlaceSlug}`;
									} else if (
										parentCategorySlug &&
										childCategorySlug &&
										!childPlaceSlug
									) {
										/*
										Input => /coiffeur/coupe-homme/gironde-33
										Output => /coiffeur/coupe-homme/33000-bordeaux
										*/
										staticContext.url = `https://www.${credentials.HOST}${localizedBasePath}/${parentCategorySlug}/${childCategorySlug}/${parentPlaceRedirect}`;
									} else if (
										parentCategorySlug &&
										!childCategorySlug &&
										childPlaceSlug
									) {
										/*
										Input => /coiffeur/gironde-33/33190-gironde-sur-dropt
										Output => /coiffeur/33190-gironde-sur-dropt
										*/
										staticContext.url = `https://www.${credentials.HOST}${localizedBasePath}/${parentCategorySlug}/${childPlaceSlug}`;
									} else if (
										parentCategorySlug &&
										!childCategorySlug &&
										!childPlaceSlug
									) {
										/*
										Input => /coiffeur/gironde-33
										Output => /coiffeur/33000-bordeaux
										*/
										staticContext.url = `https://www.${credentials.HOST}${localizedBasePath}/${parentCategorySlug}/${parentPlaceRedirect}`;
									}
									return null;
								}
							}
							const isDirectory = hardcodedSlugs.includes(wholeCountrySlug);
							const isAllowedPattern = matchesAllowedPattern(
								slugs,
								search,
								category,
								wholeCountrySlug
							);

							if (!isAllowedPattern) {
								return <UnknownURLPage />;
							}

							return (
								<PropagateSearch
									category={category}
									isRoot={false}
									search={search}
								>
									{isDirectory ? (
										<CategoryDirectoryPage
											location={locationWithoutLocale}
											{...(category ? { parentCategory: category } : search)}
										/>
									) : category ? (
										category.isBusinessCategory ? (
											<BusinessCategoriesPage
												category={category}
												history={history}
												location={locationWithoutLocale}
											/>
										) : (
											<CategoryPage
												category={category}
												history={history}
												location={locationWithoutLocale}
											/>
										)
									) : (
										<SearchPage
											location={locationWithoutLocale}
											search={search}
										/>
									)}
								</PropagateSearch>
							);
						} else if (business) {
							if (business.isBooking) {
								return (
									<BusinessPage
										business={business}
										hasStartedBooking
										history={history}
										initialServiceId={safeRead(locationWithoutLocale, [
											'state',
											'initialServiceId'
										])}
										location={locationWithoutLocale}
										noRedirection={true}
									/>
								);
							} else if (business.isBookingGiftVoucher) {
								return (
									<BusinessPage
										business={business}
										hasStartedBookingGiftVoucher
										history={history}
										initialServiceId={safeRead(locationWithoutLocale, [
											'state',
											'initialServiceId'
										])}
										location={locationWithoutLocale}
										noRedirection={true}
									/>
								);
							} else if (business.isCACShopping) {
								const {
									isClickAndCollect,
									isClickAndShop,
									shippingExplanationSentence
								} = location.state || {};
								return (
									<Layout
										hasBackgroundColor
										hideBreadcrumbs
										hideFooter
										hideNavigationItems
										withHeaderMarginTop
									>
										<ClickAndCollectFlow
											business={business}
											isCACShopping
											isClickAndCollect={isClickAndCollect}
											isClickAndShop={isClickAndShop}
											location={locationWithoutLocale}
											shippingExplanationSentence={shippingExplanationSentence}
											onSuccess={() => history.push(routes.myAccountOrders)}
										/>
									</Layout>
								);
							} else {
								return (
									<BusinessPage
										business={business}
										hasStartedBooking={false}
										history={history}
										location={locationWithoutLocale}
									/>
								);
							}
						} else {
							if (isNativeApp) return <WebviewRedirections />;
							else return <UnknownURLPage />;
						}
					}}
				</GoogleComponent>
			)}
		</AlgoliaComponent>
	);
});

function locationSlugs(url) {
	let slugs = (url || '').split('/').filter(x => !!x);
	let localeSlug = 'fr-FR';

	const [maybeLocaleSlug, ...rest] = slugs;
	if (
		maybeLocaleSlug &&
		['de-de', 'nl-be', 'fr-be'].includes(maybeLocaleSlug.toLowerCase())
	) {
		slugs = rest;
		localeSlug = maybeLocaleSlug;
	}

	return Object.assign(
		{ slugs, localeSlug },
		groupBy(slugs, slug =>
			isPage(slug) ||
			isBounds(slug) ||
			isBooking(slug) ||
			isBookingGiftVoucher(slug) ||
			isBookingGiftVoucherVariable(slug) ||
			isShopping(slug) ||
			isCACShopping(slug) ||
			isUserLocation(slug) ||
			isWholeCountry(slug)
				? 'hardcodedSlugs'
				: isGooglePlace(slug)
				? 'googleSlugs'
				: 'algoliaSlugs'
		)
	);
}

function isPage(slug) {
	return slug.match(/^page-\d+$/);
}

function isGooglePlace(slug) {
	return slug.match(/^gp_/);
}

function isBooking(slug) {
	return slug === 'reservation';
}

function isBookingGiftVoucher(slug) {
	return slug === 'reservation_gift_voucher';
}

function isBookingGiftVoucherVariable(slug) {
	return slug === 'gift_voucher_variable';
}

/**
 * @deprecated it should probably be deleted by not 100% confident
 * @param slug
 * @returns {boolean}
 */
function isShopping(slug) {
	return slug === 'eshop';
}

function isCACShopping(slug) {
	return slug === 'boutique_en_ligne';
}

function isWholeCountry(slug) {
	return ['france', 'deutschland', 'belgique', 'belgie'].includes(slug);
}

function algoliaQueries(slugs, language) {
	const filters = slugs
		.map(s => {
			return `slug:"${s}" OR slug_${language}:"${s}"`;
		})
		.join(' OR ');
	const base = {
		filters,
		attributesToHighlight: []
	};
	return {
		categories: {
			index: credentials.CATEGORIES_INDEX,
			...base
		},
		places: {
			index: credentials.PLACES_INDEX,
			...base
		},
		businesses: {
			index: credentials.BUSINESSES_INDEX,
			...base
		}
	};
}

function googleQuery(slugs) {
	const slug = slugs[0];
	const matches = slug && slug.match(`^gp_(.*)`);
	return matches && matches[1];
}

function getSearch({
	hardcodedSlugs,
	algoliaData,
	googlePlace,
	algoliaIsLoading,
	googleIsLoading,
	locationState
}) {
	if (algoliaIsLoading || googleIsLoading) {
		if (locationState) {
			return locationState;
		} else {
			return { isLoading: true };
		}
	} else {
		const categories = safeRead(algoliaData, ['categories', 'data']) || [];
		const parentCategory = categories.find(c => c.depth === 1);
		const category = categories.find(c => c.depth === 2);
		const places = safeRead(algoliaData, ['places', 'data']) || [];
		const parentPlace =
			places.find(p => p.depth === 1) ||
			places.find(p => p.depth === 2 && p.type === 'locality');
		const place = places.find(
			p =>
				p.depth === 2 &&
				((parentPlace && !parentPlace.redirect && p.type !== 'locality') ||
					(parentPlace && parentPlace.redirect && p.type === 'locality'))
		);
		const business = safeRead(algoliaData, ['businesses', 'data', 0]);
		const userLocation = userLocationFromSlugs(hardcodedSlugs);
		const bounds = boundsFromSlugs(hardcodedSlugs);
		const page = pageFromSlugs(hardcodedSlugs);
		// prefer data from locationState for googlePlace
		if (googlePlace && safeRead(locationState, ['search', 'googlePlace'])) {
			googlePlace = safeRead(locationState, ['search', 'googlePlace']);
		}
		// TODO validate all slugs have been resolved and match slug length
		if (business && !parentCategory && !parentPlace && !googlePlace) {
			const isBooking = isBookingFromSlugs(hardcodedSlugs);
			const isBookingGiftVoucher =
				isBookingGiftVoucherFromSlugs(hardcodedSlugs);
			const isBookingGiftVoucherVariable =
				isBookingGiftVoucherVariableFromSlugs(hardcodedSlugs);
			const isShopping = isShoppingFromSlugs(hardcodedSlugs);
			const isCACShopping = isCACShoppingFromSlugs(hardcodedSlugs);
			return {
				business: {
					...business,
					isBooking,
					isBookingGiftVoucher,
					isBookingGiftVoucherVariable,
					isShopping,
					isCACShopping
				}
			};
		} else {
			if (parentCategory) {
				if (category || parentPlace || googlePlace || userLocation) {
					return {
						search: {
							parentCategory,
							category,
							parentPlace,
							place,
							googlePlace,
							userLocation,
							page,
							bounds
						}
					};
				} else {
					return {
						category: parentCategory
					};
				}
			} else if (googlePlace) {
				return {
					search: {
						googlePlace,
						page,
						bounds
					}
				};
			} else if (userLocation) {
				return {
					search: {
						userLocation,
						page,
						bounds
					}
				};
			} else {
				return {};
			}
		}
	}
}

function boundsFromSlugs(slugs) {
	const slug = slugs.find(s => isBounds(s));
	if (slug) {
		const bounds = slug
			.replace(/^@/, '')
			.split(',')
			.map(b => parseFloat(b));
		return {
			ne: {
				lat: bounds[0],
				lng: bounds[1]
			},
			sw: {
				lat: bounds[2],
				lng: bounds[3]
			}
		};
	} else {
		return null;
	}
}

function pageFromSlugs(slugs) {
	const slug = slugs.find(s => isPage(s));
	return parseInt(slug && slug.match(/^page-(\d+)$/) && RegExp.$1) || null;
}

function isBookingFromSlugs(slugs) {
	const slug = slugs.find(s => s === 'reservation');
	return !!slug;
}

function isBookingGiftVoucherFromSlugs(slugs) {
	const slug = slugs.find(s => s === 'reservation_gift_voucher');
	return !!slug;
}

function isBookingGiftVoucherVariableFromSlugs(slugs) {
	const slug = slugs.find(s => s === 'gift_voucher_variable');
	return !!slug;
}

/**
 * @deprecated it should probably be deleted by not 100% confident
 * @param slugs
 * @returns {boolean}
 */
function isShoppingFromSlugs(slugs) {
	const slug = slugs.find(s => s === 'eshop');
	return !!slug;
}

function isCACShoppingFromSlugs(slugs) {
	const slug = slugs.find(s => s === 'boutique_en_ligne');
	return !!slug;
}

function userLocationFromSlugs(slugs) {
	const slug = slugs.find(s => isUserLocation(s));
	if (slug) {
		const userLocation = slug
			.replace(/^around_me@/, '')
			.split(',')
			.map(b => parseFloat(b));
		return {
			lat: userLocation[0],
			lng: userLocation[1]
		};
	} else {
		return null;
	}
}
