import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Map, OpeningHours } from '@planity/components';
import { capitalize, invokeLambda, parseOpeningHours } from '@planity/helpers';
import { DirectoryForm } from './directory_form';
import credentials from '@planity/credentials';
import {
	DEFAULT_LOCALE,
	useLocalization,
	useLocalizedRoutes,
	useTranslation
} from '@planity/localization';
import { SplitPage } from '../../layout';
import { Button, Card, Container } from '@planity/ui';
import { HiddenMap } from '@planity/ui/uikit/map';
import classNames from 'classnames/bind';
import useStyles from 'isomorphic-style-loader/useStyles';
import MainInfo from './main_info';
import { breakpoints, WithScreenDimensions } from '@planity/theme';
import { isNativeApp } from '@planity/webview';
import styles from './business_directory_page.module.scss';

export const BusinessDirectoryPage = withRouter(
	({
		business,
		businessId,
		categories,
		parentPlace,
		place,
		parentSearch,
		staticContext
	}) => {
		const { routes } = useLocalizedRoutes();
		const { t } = useTranslation();
		const { locale } = useLocalization();
		const [isMapShowed, setIsMapShowed] = useState(false);
		const isGermany = locale === 'de-DE';
		const queryParameterCampaign = 'utm_source=planity&utm_campaign=directory';

		useStyles(styles);
		const classes = classNames.bind(styles)({
			directory: true
		});
		const { plStatus } = business;
		if (plStatus === 0 && staticContext) {
			staticContext.status = 302;
			const _locale = locale === DEFAULT_LOCALE ? '' : `/${locale}`;
			if (!parentSearch?.parentCategory?.slug) {
				staticContext.url = `https://www.${credentials.HOST}${_locale}`;
				return null;
			}
			if (!parentPlace?.slug) {
				staticContext.url = `https://www.${credentials.HOST}${_locale}/${parentSearch.parentCategory.slug}`;
				return null;
			}
			staticContext.url = `https://www.${credentials.HOST}${_locale}/${parentSearch.parentCategory.slug}/${parentPlace.slug}`;
			return null;
		}

		const openingHours = parseOpeningHours(business.openingHours, {
			convertToMinutes: false
		});
		const relevantPlace = place || parentPlace;
		const placeName = relevantPlace
			? sentence([
					relevantPlace.preposition || t('common.atPlace'),
					relevantPlace.name
			  ])
			: '';
		const type = (business.types || '').split(',').filter(x => !!x)[0];
		const category = categories && categories.find(c => c.objectID === type);
		// Ce/cette/cet. For instance, only available in germany because laziness
		const demonstrativeAdjective = category && category?.demonstrativeAdjective;
		const categoryName =
			(category && (category.singular || category.name)) || 'salon';
		const preposition = capitalize(
			demonstrativeAdjective ||
				(categoryName && categoryName.match(/^[aeiou]/) ? 'Cet' : 'Ce')
		);

		//TODO : remove function after Mapbox usage test
		const onShowMapClick = async () => {
			setIsMapShowed(true);
			try {
				await invokeLambda('countMapClicks', {
					page: 'business_directory_page'
				});
			} catch (error) {
				console.error(error);
			}
		};
		return (
			<div className={classes}>
				<WithScreenDimensions>
					{({ windowWidth }) => {
						const mapHeight = windowWidth >= breakpoints.tablet ? 574 : 170;
						return (
							<Container className={styles.container} size={'large'}>
								<MainInfo business={business} isDirectory />
								<SplitPage.Wrapper className={styles.split}>
									<div className={styles.columns}>
										<SplitPage.Main className={styles.main}>
											<DirectoryForm
												business={business}
												businessId={businessId}
												categoryName={categoryName}
												placeholderText={t(
													'business.directoryForm.placeholder'
												)}
												preposition={preposition}
												submitText={t('business.directoryForm.submit')}
												submittingText={t('business.directoryForm.submitting')}
											/>
											<Card
												title={t('business.controlYourPage.title', {
													businessName: business.name,
													placeName: placeName || ''
												})}
											>
												<p className={styles.description}>
													{t('business.controlYourPage.subtitle')}
												</p>
												<ul className={styles.ulDescription}>
													{Array.isArray(t('business.controlYourPage.text'))
														? t('business.controlYourPage.text')?.map(
																(line, i) => (
																	<li className={styles.li} key={i}>
																		{line}
																	</li>
																)
														  )
														: null}
												</ul>

												{!isNativeApp && (
													<Button
														isExternal
														isFullMobile
														label={t('business.controlYourPage.link')}
														obfuscateLink
														to={
															isGermany
																? `${routes.becomePartnerBasePath}?${queryParameterCampaign}`
																: `${routes.becomePartner}?${queryParameterCampaign}`
														}
													/>
												)}
											</Card>
											{!!openingHours && (
												<OpeningHours
													business={business}
													className={'isFullWidth'}
													openingHours={openingHours}
												/>
											)}
										</SplitPage.Main>
										<SplitPage.Aside className={styles.aside}>
											<h2 className={styles.title}>{t('business.map')}</h2>
											<div className={styles.card}>
												{isMapShowed ? (
													<Map
														business={business}
														height={mapHeight}
														showInteractiveMap
														width={
															windowWidth < breakpoints.tablet
																? windowWidth
																: undefined
														}
													/>
												) : (
													<HiddenMap
														height={mapHeight}
														setIsMapShowed={onShowMapClick}
													/>
												)}
											</div>
										</SplitPage.Aside>
									</div>
								</SplitPage.Wrapper>
							</Container>
						);
					}}
				</WithScreenDimensions>
			</div>
		);
	}
);

function sentence(words) {
	return (words || [])
		.filter(x => !!x)
		.join(' ')
		.replace(/\s+/g, ' ')
		.replace(/\s,/g, ',');
}
