import { useTranslation } from '@planity/localization';
import React from 'react';
import Helmet from 'react-helmet';
import { useLocalization } from '@planity/localization';

export function BusinessPageMetadata({
	business,
	businessId,
	categories,
	place
}) {
	const { t } = useTranslation();
	const businessName = business?.name;
	const businessTypes = business?.types;
	const plStatus = business?.plStatus;
	const { language } = useLocalization();

	const placeName = place
		? t('business.meta.placeTitle', {
				preposition:
					place.preposition || t('business.meta.defaultPlacePreposition'),
				name: place.name || ''
		  })
		: '';
	const type = (businessTypes || '').split(',').filter(x => !!x)?.[0];
	const category = categories?.find(c => c.objectID === type);
	const categoryName = category
		? category.singular ||
		  category.name ||
		  t('business.meta.defaultCategoryName')
		: '';
	const title = metaTitle(
		{
			businessName,
			plStatus,
			categoryName,
			placeName,
			language
		},
		t
	);
	const description =
		plStatus > 2
			? t('business.meta.description.businessIsOurs', {
					businessName,
					categoryName,
					placeName
			  })
			: t('business.meta.description.businessIsNotOurs', {
					businessName,
					categoryName,
					placeName
			  });

	// make a preview that respect pro crop
	const [cropMadeByHand] = (
		business?.pictures?.[0]?.thumbs?.d_main?.t || ''
	).split('/');
	const crop = cropMadeByHand.length ? `${cropMadeByHand}/` : '';

	const image = business?.pictures?.at(0)?.id || business?.pictures?.at(0);

	const imageUrl = image
		? `https://res.cloudinary.com/planity/image/upload/${crop}q_auto,f_auto,w_1200,h_630/${image}`
		: t('meta.homepagePreview'); // Default picture when nothing available

	return (
		<Helmet>
			<title>{title}</title>
			<meta content={description} name={'description'} />
			<meta content={title} property={'og:title'} />
			<meta content={imageUrl} property={'og:image'} />
			{!image && <meta content={1200} property={'og:image:width'} />}
			{!image && <meta content={630} property={'og:image:height'} />}
			<meta content={description} property={'og:description'} />
			<meta content='Planity' property='product:brand' />
			<meta content='in stock' property='product:availability' />
			<meta content='new' property='product:condition' />
			<meta content={businessId} property='product:retailer_item_id' />
			<meta content='0' property='product:price:amount' />
			<meta content='EUR' property='product:price:currency' />
			{business.isTestBusiness && (
				<meta content='noindex, nofollow' name='robots' />
			)}
		</Helmet>
	);
}

const metaTitle = (
	{ businessName, plStatus, categoryName, placeName, language },
	t
) => {
	const title = [businessName, ' : ', categoryName, placeName];
	const separator = ' - ';
	if (plStatus > 2) {
		if ((sentence(title) + separator + t('business.meta.brand')).length <= 60) {
			if (!(language === 'fr' && businessName.match(/num[eé]ro/i))) {
				// See https://planity.atlassian.net/browse/SEO-12
				title.push(separator + t('business.meta.brand'));
			}
		}
		if (
			(sentence(title) + separator + t('business.meta.onlineAppointment'))
				.length <= 60
		)
			title.push(separator + t('business.meta.onlineAppointment'));
	}
	return sentence(title);
};

function sentence(words) {
	return (words || [])
		.filter(x => !!x)
		.join(' ')
		.replace(/\s+/g, ' ')
		.replace(/\s,/g, ',');
}
