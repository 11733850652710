import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './business_reviews.module.scss';
import { BusinessReview, Button, Pagination, Spinner } from '@planity/ui';
import useStyles from 'isomorphic-style-loader/useStyles';
import { PaginatedFirebaseSubscription } from '@planity/components';
import { format, parseISO } from 'date-fns';
import { useTranslation } from '@planity/localization';
import { formatRating } from '@planity/helpers';

export function BusinessReviews({ className, hasPagination, review }) {
	useStyles(styles);
	const classes = classNames.bind(styles);
	const ref = useRef(null);
	const { t } = useTranslation();
	const PAGE_SIZE = 5;
	const formattedDate = '3000-01-01 00:00:00';
	const defaultDate = '1970-01-01 00:00:00';

	return (
		<div className={classes({ reviews: true }, className)} ref={ref}>
			<PaginatedFirebaseSubscription
				endAt={formattedDate}
				limitToLast={PAGE_SIZE + 1}
				orderByChild={'ratedAt'}
				path={`business_reviews/${review.businessId}`}
				hasPagination={hasPagination}
				renderData={({ data, isLoading, hasMore, isCurrent }) => (
					<div
						className={classes({
							reviewsChunk: true,
							isCurrent
						})}
					>
						{Object.keys(data || {})
							.sort(
								(a, b) =>
									format(parseISO(data[b].ratedAt || defaultDate), 't') -
									format(parseISO(data[a].ratedAt || defaultDate), 't')
							)
							.slice(0, 5)
							.map(reviewId => (
								<BusinessReview
									date={format(
										parseISO(data[reviewId].ratedAt || defaultDate),
										'dd/MM/yyyy'
									)}
									key={reviewId}
									note={formatRating(data[reviewId].reviewRating)}
									text={data[reviewId].reviewBody}
									reply={data[reviewId].reviewAnswer}
									replyFrom={review.businessName}
									hasMore={hasMore}
								/>
							))}

						{isLoading && !data && isCurrent && (
							<div className={styles.spinner}>
								<Spinner />
							</div>
						)}
					</div>
				)}
				renderLoadMore={
					!hasPagination &&
					(loadMore => (
						<Button
							label={t('business.reviews.seeMore')}
							type='tertiary'
							isFullMobile
							onClick={loadMore}
						/>
					))
				}
				renderPagination={
					hasPagination &&
					(({ navToPrev, navToNext, pageNumber, hasMore, navByPage }) => (
						<Pagination
							current={pageNumber}
							total={review?.rating?.ratingsCount}
							hasMore={hasMore}
							handleEventClick={(type, value) => {
								ref?.current?.scrollIntoView({
									behavior: 'smooth',
									block: 'center'
								});
								type === 'prev'
									? navToPrev()
									: type === 'next'
									? navToNext()
									: navByPage(value);
							}}
						/>
					))
				}
				shard={'public'}
			/>
		</div>
	);
}
